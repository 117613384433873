import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import {
  AgreementService,
  AuthenticationService,
  SearchService,
  SubscriptionsService,
  YoutubeService,
} from 'sostereo-services';
import { TrackingService } from './tracking.service';
import { clone, debounce } from 'lodash-es';
import { searchBarSection } from '../../modules/shared-layout/configs/search-bar-section.config';
import { CommonService } from './common.service';
import { PubnubGeneralService } from '../../layout/services/pubnub-general.service';
import { ToastrService } from 'ngx-toastr';
import { PlayerService } from './player.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchConfigService } from './search-config.service';

@Injectable({
  providedIn: 'root',
})
export class SearchTagsService implements OnDestroy {
  public selectedTags = [];
  public settingsList = clone(searchBarSection().settingsList);
  public eventSettingList: Subject<void> = new Subject<any>();
  public noResults = false;
  public searchLogoSrc = './assets/images/logo-img.svg';
  public agreement: any;
  public useOriginalCatalog = false;
  public currentCatalog = null;
  public tagAdded = new Subject<any>(); // TODO: subject without subscriptions
  public tagRemoved = new Subject<any>();
  public genericTagUpdated = new BehaviorSubject<any>('');
  public budgetTagsRemoved = new Subject<void>();
  public updateSelectedTags = new Subject<void>();
  public allTagsRemoved = new Subject<void>();
  public resultsUpdate = new Subject<any>();
  public searchLogoUpdate = new Subject<void>();
  public agreementUpdate = new Subject<void>();
  public youtubePendingTag;
  public embeddedVersion;
  public youtubeRef;
  public firstSearch = true;
  private subdomain = null;
  private sortedCategories = this.searchConfigService.getSorterCategories();

  private pnData: any = {};
  private static pubnubEvent$: Subscription;
  private YOUTUBE_REF = 'SOSTEREO.Ref-Youtube';
  private isMobileOrTablet =
    this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();

  constructor(
    private searchService: SearchService,
    private youtubeService: YoutubeService,
    private agreementService: AgreementService,
    private trackingService: TrackingService,
    private authenticationService: AuthenticationService,
    private pubnubGeneralService: PubnubGeneralService,
    private subscriptionsService: SubscriptionsService,
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService,
    private playerService: PlayerService,
    private deviceDetectorService: DeviceDetectorService,
    private searchConfigService: SearchConfigService,
    @Inject('environment') public environment,
  ) {
    this.getYtReference = debounce(this.getYtReference, 500);
    this.subdomain = this.commonService.getSubdomain();
    if (this.subdomain) {
      this.searchLogoSrc = `https://img-sostereo.sostereo.com/sostereo-images-${
        this.environment.production ? 'production' : 'stg'
      }/logos/${this.subdomain}.png`;
    }
    this.authenticationService.accessData.subscribe(() => {
      if (this.commonService.isAllowed(['GetAgreement'], ['UpdateSearchSettings'])) {
        this.subdomain = this.commonService.getSubdomain();
        this.searchLogoSrc = `https://img-sostereo.sostereo.com/sostereo-images-${
          this.environment.production ? 'production' : 'stg'
        }/logos/${this.subdomain}.png`;
        this.useOriginalCatalog = false;
        this.searchLogoUpdate.next();
      }
    });
  }

  public reorderCategories(suggestions) {
    let categories = clone(suggestions);
    categories = categories.filter((c) => c.items.length > 0);
    categories.sort((a, b) => {
      if (!a.items || a.items.length === 0) return 1;
      if (!b.items || b.items.length === 0) return -1;
      if (a.items[0].score > b.items[0].score) {
        return -1;
      }
      if (a.items[0].score < b.items[0].score) {
        return 1;
      }
      return 0;
    });
    return categories;
  }

  getArtistName(item: any) {
    let songArtistNames = '';
    const preferredVersion = item._source.versions.find((v) => v.preferredVersion);
    if (preferredVersion) {
      preferredVersion.artists.forEach((a) => {
        songArtistNames =
          songArtistNames === '' ? a.artistName : `${songArtistNames}, ${a.artistName}`;
      });
    }
    return songArtistNames;
  }

  public mapSuggestions(suggestions) {
    const mappedSuggestions = this.sortedCategories.map((sc) => {
      const suggestion = suggestions[sc.id];
      if (suggestion) {
        sc.paging = suggestions[sc.id].paging;
        switch (sc.id) {
          case 'songs':
            sc.items = suggestions[sc.id].items.map((i) => {
              let songArtistNames = this.getArtistName(i);
              return {
                category: sc.id,
                label: i._source.name,
                highlight: i.highlight ? i.highlight['versions.name'][0] : i._source.name,
                artistNames: songArtistNames,
                id: i._id,
                score: i._score,
              };
            });
            break;
          case 'artists':
            sc.items = suggestions[sc.id].items.map((i) => {
              return {
                category: sc.id,
                label: i._source.name,
                highlight: i.highlight ? i.highlight.name[0] : i._source.name,
                id: i._id,
                slug: i._source.slug,
                score: i._score,
              };
            });
            break;
          case 'lyric':
          case 'lyric-strict':
            sc.items = suggestions[sc.id].items.map((i) => {
              let songArtistNames = this.getArtistName(i);
              const versions = i._source.versions;
              return {
                category: sc.id,
                label: i._source.name,
                highlights:
                  i.highlight && i.highlight['versions.lyric'] ? i.highlight['versions.lyric'] : [],
                artistNames: songArtistNames,
                versions: versions,
                id: i._id,
                score: i._score,
              };
            });
            break;
          case 'recording-location':
            if (
              suggestions[sc.id].aggregations.countries &&
              suggestions[sc.id].aggregations.countries.buckets &&
              this.commonService.isAllowed(['UpdateSearchSettings'], [])
            ) {
              sc.aggregations = suggestions[sc.id].aggregations.countries.buckets.map((i) => {
                return {
                  category: sc.id,
                  label: i.key,
                  count: i.doc_count,
                };
              });
            }
            break;
          case 'available-markets':
            if (
              suggestions[sc.id].aggregations.countries &&
              suggestions[sc.id].aggregations.countries.buckets &&
              this.commonService.isAllowed(['UpdateSearchSettings'], [])
            ) {
              const markets = suggestions[sc.id].aggregations.countries.buckets.filter(
                (i) => i.key !== '',
              );
              sc.aggregations = markets.map((i) => {
                return {
                  category: sc.id,
                  label: i.key,
                  count: i.doc_count,
                };
              });
            }
            break;
          case 'playlists':
            if (suggestions[sc.id].items.length > 0) {
              sc.items = suggestions[sc.id].items.map((i) => {
                return {
                  category: sc.id,
                  label: i._source.name,
                  highlight: {
                    name: i?.highlight?.name && i?.highlight?.name[0],
                    tags: i?.highlight?.tags
                      ? i?.highlight?.tags
                      : i?.highlight?.tagsText && i?.highlight?.tagsText,
                    description: i?.highlight?.description && i?.highlight?.description[0],
                  },
                  id: i._id,
                  totalPublicSongs: i._source.totalPublicSongs,
                  slug: i._source.slug,
                  thumbUrl: i._source.thumbUrl,
                  score: i._score,
                };
              });
            } else {
              sc.items = [];
            }
            break;
          default:
            sc.items = suggestions[sc.id].items.map((i) => {
              return {
                category: sc.id,
                label: i._source.label,
                highlight: i.highlight ? i.highlight.label : i._source.label,
                id: i._id,
                slug: i._slug || i._source.slug,
                type: i._source.category,
                score: i._score,
              };
            });
            break;
        }
      }
      return sc;
    });
    const noAggregations = mappedSuggestions.every((ms) => {
      if (!ms.aggregations) {
        return true;
      }
      return ms.aggregations.length === 0;
    });
    this.noResults = mappedSuggestions.every((ms) => ms.items.length === 0) && noAggregations;
    this.resultsUpdate.next(this.noResults);
    return this.noResults ? null : this.reorderCategories(mappedSuggestions);
  }

  public addVersionTag(versionTag) {
    const oldVersionTag = this.selectedTags.find((t) => t.category === 'type');
    if (oldVersionTag) {
      this.selectedTags[this.selectedTags.indexOf(oldVersionTag)] = versionTag;
    } else {
      this.selectedTags.push(versionTag);
    }
    this.tagAdded.next(versionTag);
    this.checkResults();
    this.trackingService.track(
      'Song-Type Filter Selected',
      {
        songType: versionTag,
        version: 1.0,
      },
      {
        event_action: 'Song-type filter selected',
        event_type: 'Version Tag',
        event_value: versionTag,
      },
    );
  }

  public addBudgetOptionTag(budgetOptionTag) {
    this.selectedTags.push(budgetOptionTag);
    this.tagAdded.next(budgetOptionTag);
  }

  public addBudgetAmountTag(budgetAmountTag) {
    const oldBudgetAmountTag = this.selectedTags.find((t) => t.type === 'budgetAmount');
    if (oldBudgetAmountTag) {
      this.selectedTags.splice(this.selectedTags.indexOf(oldBudgetAmountTag), 1);
      this.selectedTags.push(budgetAmountTag);
    } else {
      this.selectedTags.push(budgetAmountTag);
    }
    this.tagAdded.next(budgetAmountTag);
    this.checkResults();
  }

  public addBpmRangeTag(bpmRangeTag) {
    const oldBpmRangeTag = this.selectedTags.find((t) => t.type === 'bpmRange');
    if (oldBpmRangeTag) {
      this.selectedTags.splice(this.selectedTags.indexOf(oldBpmRangeTag), 1);
      this.selectedTags.push(bpmRangeTag);
    } else {
      this.selectedTags.push(bpmRangeTag);
    }
    this.tagAdded.next(bpmRangeTag);
  }

  public refSongTag(refSongTag) {
    const oldReSongfTag = this.selectedTags.find((t) => t.type === 'refSong');
    if (oldReSongfTag) {
      this.selectedTags.splice(this.selectedTags.indexOf(oldReSongfTag), 1);
      this.selectedTags.push(refSongTag);
    } else {
      this.selectedTags.push(refSongTag);
    }
    this.tagAdded.next(refSongTag);
    this.checkResults();
  }

  public addStatsTag(statsTag) {
    const oldStatsTag = this.selectedTags.find((t) => t.type === statsTag.type);
    if (oldStatsTag) {
      this.selectedTags.splice(this.selectedTags.indexOf(oldStatsTag), 1);
      this.selectedTags.push(statsTag);
    } else {
      this.selectedTags.push(statsTag);
    }
    this.tagAdded.next(statsTag);
  }

  public addYoutubeTag(refSongTag) {
    this.youtubePendingTag = refSongTag;
    this.tagAdded.next(refSongTag);
  }

  public addTag(tag) {
    const existingTag = this.selectedTags.find(
      (t) => t.slug === tag.slug && t.category === tag.category,
    );
    if (!existingTag) {
      if (tag.tags) {
        delete tag.tags;
      }
      if (tag.parentTag) {
        delete tag.parentTag;
      }
      if (tag.items) {
        delete tag.items;
      }

      this.trackingService.track(
        'Tag Added',
        {
          user: this.authenticationService.getCurrentUser(),
          tag: tag,
          query: this.getQuery(),
          version: 1.0,
        },
        {
          event_action: 'Tag added to search',
          event_type: 'Tag Added',
          element_type: tag?.category,
          element_value: tag?.label,
          element_item_type: 'Slug',
          elemnt_item_name: tag?.slug,
        },
      );
      if (tag.category === 'restrictions') {
        tag.label = `Allowed use: ${tag.label}`;
      }
      this.selectedTags.push(tag);
      this.tagAdded.next(tag);
      if (tag.category === 'songs' || tag.category === 'lyric') {
        this.router.navigateByUrl(
          `/music-sync-licensing/search?songId=${tag.id}${
            this.useOriginalCatalog ? '&catalog=sostereo' : ''
          }`,
        );
      }
      if (tag.category === 'playlists') {
        this.router.navigateByUrl(
          `/music-sync-licensing/${this.isMobileOrTablet ? 'my-playlists' : 'playlist'}/${tag.id}`,
        );
      }
    } else if (tag.category === 'youtube-ref' || tag.category === 'ref-song') {
      this.selectedTags.find((t) => t.slug === tag.slug && t.category === tag.category).label =
        tag.label;
    }
  }

  public removeTag(tag) {
    const existingTag = this.selectedTags.find(
      (t) => t.slug === tag?.slug && t.category === tag?.category,
    );
    if (existingTag) {
      this.trackingService.track(
        'Remove tag from search',
        {
          tagName: existingTag?.label,
          tagSlug: existingTag?.slug,
        },
        {
          event_action: 'Tag removed from search',
          event_type: 'Tag Removed',
          event_value: existingTag?.slug,
        },
      );
      this.selectedTags.splice(this.selectedTags.indexOf(existingTag), 1);
      this.tagRemoved.next(tag);
      if (existingTag.type === 'budgetAmount') {
        this.selectedTags = this.selectedTags.filter((st) => {
          return st.type !== 'budgetOption';
        });
        this.budgetTagsRemoved.next();
      }
      if (tag.category === 'ref-song') {
        localStorage.removeItem('SOSTEREO.similarSongRef');
      }
    }
  }

  public toggleTagType(tag) {
    const existingTag = this.selectedTags.find(
      (t) => t.slug === tag.slug && t.category === tag.category,
    );
    if (existingTag) {
      existingTag.negative = !existingTag.negative;
    }
  }

  public clearTags() {
    this.selectedTags = [];
    this.updateSelectedTags.next();
    this.allTagsRemoved.next();
  }

  private setSimilarParams(selectedTag: any, queryObject: any) {
    console.log('selectedTag', selectedTag);
    queryObject.fields = 'segmentsGroups';
    queryObject.explain = 'true';
    if (selectedTag.hasOwnProperty('seed_start')) {
      queryObject.seed_start = selectedTag.seed_start;
    }
    if (selectedTag.hasOwnProperty('seed_end')) {
      queryObject.seed_end = selectedTag.seed_end;
    }
    if (selectedTag.hasOwnProperty('seed_groups')) {
      queryObject.seed_groups = selectedTag.seed_groups;
    }
  }

  private getQuery() {
    const queryObject: any = {};
    const budgetQueries = [];
    const statsQueries = [];
    this.selectedTags.forEach((st) => {
      switch (st.category) {
        case 'type':
          queryObject.type = st.negative ? `! ${st.slug}` : st.slug;
          break;
        case 'artists':
          if (queryObject.artists) {
            queryObject.artists.push(st.negative ? `! ${st.slug}` : st.slug);
          } else {
            queryObject.artists = [st.negative ? `! ${st.slug}` : st.slug];
          }
          break;
        case 'restrictions':
          if (queryObject.restrictions) {
            queryObject.restrictions.push(st.negative ? `! ${st.slug}` : st.slug);
          } else {
            queryObject.restrictions = [st.negative ? `! ${st.slug}` : st.slug];
          }
          break;
        case 'explicit':
          queryObject.explicit = 'false';
          break;
        case 'songs':
        case 'lyric':
          queryObject.songId = st.id;
          break;
        case 'query':
          if (!queryObject.hasOwnProperty('q')) {
            queryObject.q = [st.value];
          } else {
            queryObject.q.push(st.value);
          }
          queryObject.qFields = [st.type];
          break;
        case 'budget':
          if (st.type === 'budgetOption') {
            budgetQueries.push(`maxBudget.${st.slug}`);
            queryObject[`maxBudget.${st.slug}`] = this.selectedTags.find(
              (t) => t.type === 'budgetAmount',
            ).value;
          }
          break;
        case 'youtube-ref':
          this.youtubeRef = st.songData;
          if (queryObject.seed_youtube) {
            queryObject.seed_youtube.push(st.slug);
          } else {
            queryObject.seed_youtube = [st.slug];
          }
          this.setSimilarParams(st, queryObject);
          break;
        case 'ref-song':
          queryObject.seed_songIds = st.slug;
          this.setSimilarParams(st, queryObject);
          break;
        case 'stats':
          statsQueries.push({
            minQueryProp: st.minQueryProp,
            maxQueryProp: st.maxQueryProp,
            statsRange: { min: st.minValue, max: st.maxValue },
          });
          break;
        case 'recording-location':
          if (
            queryObject['recordingLocation.country'] &&
            queryObject['recordingLocation.country'].length > 0
          ) {
            queryObject['recordingLocation.country'].push(st.negative ? `! ${st.value}` : st.value);
          } else {
            queryObject['recordingLocation.country'] = [st.negative ? `! ${st.value}` : st.value];
          }
          break;
        case 'available-markets':
          if (
            queryObject['availableMarkets.country'] &&
            queryObject['availableMarkets.country'].length > 0
          ) {
            queryObject['availableMarkets.country'].push(st.negative ? `! ${st.value}` : st.value);
          } else {
            queryObject['availableMarkets.country'] = [st.negative ? `! ${st.value}` : st.value];
          }
          break;
        case 'tiers':
          if (queryObject.tierId) {
            queryObject.tierId.push(st.negative ? `! ${st.slug}` : st.slug);
          } else {
            queryObject.tierId = [st.negative ? `! ${st.slug}` : st.slug];
          }
          break;
        case 'advanced-search':
          break;
        case 'oneStop':
        case 'covers':
        case 'hasRhythm':
          queryObject[st.category] = (!st.negative).toString();
          break;
        case 'playlist':
          break;
        default:
          if (st.category === 'bpm' && st.slug === 'bpm-range') {
            queryObject.bpmRange = { min: st.minValue, max: st.maxValue };
          } else if (queryObject.tags && queryObject.tags.length > 0) {
            queryObject.tags.push(st.negative ? `! ${st.slug}` : st.slug);
          } else {
            queryObject.tags = [st.negative ? `! ${st.slug}` : st.slug];
          }
          break;
      }
    });
    const query: any = {};
    if (queryObject.tags) {
      query.tags = queryObject.tags.toString();
    }
    if (queryObject['recordingLocation.country']) {
      query['recordingLocation.country'] = queryObject['recordingLocation.country'].toString();
    }
    if (queryObject['availableMarkets.country']) {
      query['availableMarkets.country'] = queryObject['availableMarkets.country'].toString();
    }
    if (queryObject.artists) {
      query.artists = queryObject.artists.toString();
    }
    if (queryObject.restrictions) {
      query.restrictions = queryObject.restrictions.toString();
    }
    if (queryObject.songId) {
      query.songId = queryObject.songId;
    }
    if (queryObject.bpmRange) {
      query['min.bpm'] = queryObject.bpmRange.min;
      if (queryObject.bpmRange.max < 200) {
        query['max.bpm'] = queryObject.bpmRange.max;
      }
    }
    if (queryObject.q) {
      query.q = queryObject.q.toString();
    }
    if (queryObject.qFields) {
      query.qFields = queryObject.qFields.toString();
    }
    if (queryObject.type && queryObject.type.includes('instrumental')) {
      query.vocals = false;
    }
    if (queryObject.type && queryObject.type.includes('full')) {
      query.vocals = true;
    }
    if (queryObject.explicit) {
      query.explicit = false;
    }
    if (queryObject.tierId) {
      query.tierId = queryObject.tierId.toString();
    }
    if (queryObject.oneStop) {
      query.isOneStop = queryObject.oneStop;
    }
    if (queryObject.covers) {
      query.isCover = queryObject.covers;
    }
    if (queryObject.hasRhythm) {
      query.hasRhythm = queryObject.hasRhythm;
    }

    if (statsQueries.length > 0) {
      statsQueries.forEach((fq) => {
        query[fq.minQueryProp] = fq.statsRange.min;
        if (fq.statsRange.max < 10000000) {
          query[fq.maxQueryProp] = fq.statsRange.max;
        }
      });
    }
    if (budgetQueries.length > 0) {
      budgetQueries.forEach((bq) => {
        query[bq] = queryObject[bq];
      });
    }
    if (queryObject.seed_youtube) {
      query.seed_youtube = queryObject.seed_youtube.toString();
    }
    if (queryObject.seed_songIds) {
      query.seed_songIds = queryObject.seed_songIds;
    }
    if (queryObject.seed_songIds || queryObject.seed_youtube) {
      query.fields = queryObject.fields;
      query.explain = queryObject.explain;
      if (queryObject.hasOwnProperty('seed_start')) {
        query.seed_start = queryObject.seed_start;
      }
      if (queryObject.hasOwnProperty('seed_end')) {
        query.seed_end = queryObject.seed_end;
      }
      if (queryObject.hasOwnProperty('seed_groups')) {
        query.seed_groups = queryObject.seed_groups;
      }
    }
    if (this.useOriginalCatalog && this.subdomain) {
      query.catalog = 'sostereo';
    }
    if (this.currentCatalog) {
      query.catalog = this.currentCatalog;
    }

    const settings = this.authenticationService.getUserSessionSettings();
    if (settings?.profile?.value && settings.profile?.value !== 'advanced') {
      query.profile = settings.profile.value;
    }

    return query;
  }

  public checkResults() {
    const query = this.getQuery();
    const settings = this.authenticationService.getUserSessionSettings();
    const withProfile = !!query.profile || settings?.profile?.label === 'Relevance';
    (this.settingsList as any).forEach((sl) => {
      sl.items.forEach((s) => {
        if (sl.category === 'advancedSettings' || !withProfile || (withProfile && s.avoidProfile)) {
          if (s.value && s.defaultValue !== s.value) {
            query[s.slug] = s.value;
          }
        }
      });
    });
    console.log('Check results >>> ', query);
    if (this.embeddedVersion) {
      query.embeddingsVersion = this.embeddedVersion;
    }
    this.searchService.query(query).subscribe(
      (res) => {
        this.noResults = res.data.items.length <= 0;
        this.resultsUpdate.next(res.data);
      },
      () => {
        this.noResults = true;
        this.resultsUpdate.next(null);
      },
    );
  }

  public sendSearch(similarSong?, lyricsOpen?) {
    let query: any = this.getQuery();
    const settings = this.authenticationService.getUserSessionSettings();
    const withProfile = !!query.profile || settings?.profile?.label === 'Relevance';
    if (query.hasOwnProperty('seed_youtube') && this.youtubeRef) {
      localStorage.setItem('SOSTEREO.similarSongRef', JSON.stringify(this.youtubeRef));
    }
    this.youtubeRef = null;
    if (similarSong && query.hasOwnProperty('artists')) {
      this.removeTag({ slug: query.artists, category: 'artists' });
      delete query.artists;
    }
    if (query.hasOwnProperty('songId')) {
      this.removeTag({ id: query.songId, category: 'songs' });
      if (similarSong) {
        delete query.songId;
      } else {
        this.selectedTags = [];
        query = { songId: query.songId };
        this.updateSelectedTags.next();
      }
    }
    let stringQuery = '';
    Object.keys(query).forEach((key) => {
      stringQuery =
        stringQuery === '' ? `?${key}=${query[key]}` : `${stringQuery}&${key}=${query[key]}`;
    });
    (this.settingsList as any).forEach((sl) => {
      sl.items.forEach((s) => {
        if (sl.category === 'advancedSettings' || !withProfile || (withProfile && s.avoidProfile)) {
          if ((s.value || s.value == 0) && s.defaultValue !== s.value) {
            if (stringQuery === '') {
              stringQuery =
                sl.category === 'advancedSettings' && s.type !== 'text'
                  ? `?${s.value}`
                  : `?${s.slug}=${s.value}`;
            } else {
              stringQuery =
                sl.category === 'advancedSettings' && s.type !== 'text'
                  ? `${stringQuery}&${s.value}`
                  : `${stringQuery}&${s.slug}=${s.value}`;
            }
          }
        }
      });
    });
    this.trackingService.track('Settings added to search ', {
      settings: this.settingsList,
    });
    const budgetTags = this.selectedTags.filter((st) => st.category === 'budget');
    this.trackingService.track('Play Search', {
      user: this.authenticationService.getCurrentUser(),
      tags: this.selectedTags,
      budget: budgetTags,
      version: 1.0,
    });
    stringQuery =
      (stringQuery === '' ? `?` : `${stringQuery}&`) +
      'page=1&song=0' +
      (this.embeddedVersion ? `&embeddingsVersion=${this.embeddedVersion}` : '');
    if (lyricsOpen) {
      stringQuery = (stringQuery === '' ? `?` : `${stringQuery}&`) + 'lyrics=open';
    }
    let baseUrl = '';
    if (this.selectedTags.some((st) => st.category === 'youtube-ref')) {
      baseUrl = '/music-sync-licensing/search';
    } else {
      baseUrl =
        (this.router.url.includes('/discover') ||
          !this.selectedTags.some((st) => st.category === 'playlist')) &&
        !this.router.url.includes('/artists-list')
          ? '/music-sync-licensing/search'
          : this.router.url.split('?')[0];
    }

    const newUrl = baseUrl + stringQuery;
    if (!this.selectedTags.some((st) => st.category === 'playlists')) {
      this.router.navigateByUrl(newUrl);
    }
  }

  public changeCatalog(original: boolean) {
    if (original) {
      this.useOriginalCatalog = true;
      this.searchLogoSrc = './assets/images/logo-img.svg';
    } else {
      this.useOriginalCatalog = false;
      const subdomain = this.commonService.getSubdomain();
      this.searchLogoSrc = `https://img-sostereo.sostereo.com/sostereo-images-production/${subdomain}.png`;
    }
    this.searchLogoUpdate.next();
    this.checkResults();
  }

  public displayCatalog(currentCatalog) {
    this.currentCatalog = currentCatalog;
    this.checkResults();
  }

  public getUserAgreement() {
    const user = this.authenticationService.getCurrentUser();
    if (user) {
      this.agreementService.me().subscribe((res) => {
        this.agreement = res.data;
        this.agreementUpdate.next();
      });
    }
  }

  public ytPubNub(ytId) {
    this.subscriptionsService
      .post({
        name: `ref_${ytId}`,
        topic: ytId,
        protocol: ['push'],
      })
      .subscribe((res) => {
        this.pubnubGeneralService.initPubNub(res.data);
        this.pubnubGeneralService.addChannel(ytId, res.data.authToken);
      });

    SearchTagsService.pubnubEvent$ = this.pubnubGeneralService.pubnubEvent.subscribe({
      next: async (x) => {
        console.log('Message >>> ', x.message);
        const res = x.message;
        if (res.statusCode >= 400) {
          this.cancelPubNubSubscription();
          this.toastr.error(
            'We couldn’t process your reference link, please try again. If the issue persist try with another link of the same track!',
            '',
            { disableTimeOut: true, closeButton: true },
          );
        }
        if (res.payload?.hasOwnProperty('percentage')) {
          this.playerService.percentageReference.next(res.payload.percentage);
        }

        if (res.payload?.hasOwnProperty('signedUrl')) {
          this.playerService.externalReference.next(res.payload.signedUrl);
        }

        if (res.payload?.searchReady && res.targetId == ytId) {
          SearchTagsService.pubnubEvent$.unsubscribe();
          this.pubnubGeneralService.removeAllListeners();
        }
      },
      error: (pbnErr) => {
        this.toastr.error(
          pbnErr?.error?.message,
          'We couldn’t process your reference link, please try again. If the issue persist try with another link of the same track!',
        );
        this.cancelPubNubSubscription();
        this.trackingService.track('Error pubnub YouTube', {
          error: pbnErr,
        });
      },
    });
  }

  async mapYTdata(message) {
    if (message.error) {
      throw new Error();
    } else {
      this.pnData = {
        ...this.pnData,
        ...message,
      };
      if (message.search_ready) {
        return message;
      }
    }
  }

  public getYtReference(videoId: string, recaptcha: string, params: any) {
    this.playerService.isProcessingReference.next('processing');
    this.ytPubNub(videoId);
    this.youtubeService.getYtReferenceSTG(videoId, recaptcha, params).subscribe({
      next: (res) => {
        const videoData = res;
        if (videoData.signedUrl && this.youtubePendingTag) {
          localStorage.setItem(this.YOUTUBE_REF, JSON.stringify({ id: videoId, ...videoData }));
          this.playerService.isProcessingReference.next('end');
          this.playerService.externalReference.next(videoData.signedUrl);
          this.playerService.percentageReference.next('0');
          this.trackingService.track(
            'YT Reference Ready',
            { videoData: videoData },
            {
              event_action: 'YT reference ready',
              event_type: 'YT Reference',
              event_value: videoId,
            },
          );
        }
      },
      error: (err) => {
        this.toastr.clear();
        this.toastr.error(
          err?.error?.message,
          'We couldn’t process your reference link, please try again. If the issue persist try with another link of the same track!',
        );
        this.playerService.isProcessingReference.next('none');
        this.playerService.percentageReference.next('0');
        console.log('Error audio-processing YT references', err);
        this.trackingService.track('getYtReference error', {
          error: err,
        });
      },
    });
  }

  public getYoutubeRefTag(videoInfo) {
    return {
      category: 'youtube-ref',
      label: videoInfo.name,
      slug: videoInfo.id,
      type: 'refSong',
      verifying: videoInfo.verifying,
      songData: videoInfo.songData,
      seed_start: videoInfo.seed_start,
      seed_end: videoInfo.seed_end,
    };
  }

  createSegmentRefYoutube(segmentsAll: any) {
    const keys = Object.keys(segmentsAll);
    keys.forEach((key) => {
      segmentsAll[key] = {
        segments: segmentsAll[key],
        selected_segment: segmentsAll[key][0],
      };
    });
    return segmentsAll;
  }

  public createRefYoutubeReady(segment?: any) {
    const video = JSON.parse(localStorage.getItem(this.YOUTUBE_REF) || '{}');
    video.segmentsGroups = this.createSegmentRefYoutube(video.segmentsGroups);
    const songData = {
      ...video,
      versions: {
        instrumental: {},
        vocals: {},
        match: {
          seed_youtube: true,
          hideOptions: true,
          artistName: '',
          name: `Ext Ref: ${video.title || video.id}`,
          files: {
            streaming: video.signedUrl,
            original: video.signedUrl,
          },
          trackId: video.id,
          _id: video.id,
        },
        others: [],
      },
    };
    songData.versions.match = { ...songData.versions.match, ...video };
    songData.versions.vocals = songData.versions.match;
    const youtubeSeedTag = this.getYoutubeRefTag({
      name: `Ext Ref: ${video.id}`,
      id: video.id,
      verifying: false,
      songData: songData,
      seed_start: segment?.start || 0,
      seed_end: segment?.duration || 35,
    });
    this.refSongTag(youtubeSeedTag);
    this.youtubePendingTag = null;
    this.sendSearch();
    this.playerService.showFullScreenSearch = false;
    this.playerService.isProcessingReference.next('none');
    this.playerService.percentageReference.next('0');
  }

  public cancelPubNubSubscription() {
    SearchTagsService.pubnubEvent$?.unsubscribe();
    this.pubnubGeneralService.removeAllListeners();
    localStorage.removeItem(this.YOUTUBE_REF);
    this.playerService.percentageReference.next('0');
    this.playerService.isProcessingReference.next('none');
  }

  ngOnDestroy() {
    SearchTagsService.pubnubEvent$?.unsubscribe();
  }
}
